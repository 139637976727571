import React, { Component } from "react";
import { resizeCount } from './rem';
class App extends Component {
  // componentDidMount(){
  //   resizeCount()
  // }
  render() {
    return <div>{this.props.children}</div>;
  }
}

export default App;
