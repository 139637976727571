import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import "lib-flexible";
import "video-react/dist/video-react.css";
import "./default.css";
import "./index.css";
import "./App";
import Router from "./Router";

ReactDOM.render(<Router />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
