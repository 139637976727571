import React, { Suspense, lazy, FC } from "react";
import { BrowserRouter, Route, Switch, withRouter } from "react-router-dom";
import { ActivityIndicator } from "antd-mobile";
import App from "./App";
import { checkTokenTime } from "~/utils/tool";
// import Page from './components/Title/index'

// export const login = [
//   {
//     path: "/",
//     title: "登录",
//     component: lazy(() => import("./pages/login/login")),
//   }
// ]

export const routes = [
  {
    path: "/homePad",
    title: "百世乐业",
    component: lazy(() => import("./pages/homePad")),
  },
  {
    path: "/selectIdentity",
    title: "选择身份",
    component: lazy(() => import("./pages/selectIdentity")),
  },
  {
    path: "/",
    title: "登录账号",
    component: lazy(() => import("./pages/loginS/index")),
  }
];

const Router: FC = () => {
  return (
    <BrowserRouter>
      <App>
        <Suspense fallback={false}>
          <Switch>
            {routes.map(({ path, component: Component, title }: any, key) => (
              <Route
                key={`${key}_`}
                exact
                path={path}
                render={(props) => {
                  // if (title !== "登录") checkTokenTime();
                  document.title = title;
                  return <Component {...props} />;
                }}
              />
            ))}
          </Switch>
        </Suspense>
      </App>
    </BrowserRouter>
  );
};
// onEnter={() => document.title = title}
export default Router;
